import React from "react";
import { Navigate } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const [auth, setAuth] = React.useState(!!localStorage.getItem("token"));
  if (!auth) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default AuthGuard;
