import React, { useCallback, useMemo } from "react";
import { Header } from "antd/es/layout/layout";
import { Dropdown, theme, Space, Avatar, Typography } from "antd";
import { Link } from "react-router-dom";

const items = [
  {
    key: "1",
    label: <Link to="/logout">Đăng Xuất</Link>,
  },
];
const LayoutHeader = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const randomColor = useMemo(
    () => Math.floor(Math.random() * 16777215).toString(16),
    []
  );
  const infoUsers = JSON.parse(localStorage.getItem("user") || "{}");
  return (
    <Header style={{ padding: " 0 20px", background: colorBgContainer }}>
      <Dropdown menu={{ items }} className={"dropdown-header"}>
        <Space className="ant-space-item-header">
          <Avatar
            style={{
              backgroundColor: `#${randomColor}`,
              verticalAlign: "middle",
            }}
            size={30}
          >
            {infoUsers?.fullName?.charAt(0)}
          </Avatar>
          <div className="group-info-sidebar">
            <Typography.Text className="username-sidebar" strong>
              {infoUsers?.fullName}
            </Typography.Text>
            <Typography.Text className="rolename-sidebar">
              {infoUsers?.roleName}
            </Typography.Text>
          </div>
        </Space>
      </Dropdown>
    </Header>
  );
};

export default LayoutHeader;
