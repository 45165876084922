import React from 'react';
import {useQueryClient} from "@tanstack/react-query";

const Logout = () => {
    const queryClient = useQueryClient();
    // clear cache
    queryClient.clear();
    // clear token
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    // redirect to login
    window.location.href = '/login';

};

export default Logout;
