import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthGuard from "./AuthGuard";
import AppLayout from "../layout";
import Logout from "../pages/auth/logout";
import NotFound from "../components/NotFound";

const LoginPage = React.lazy(() => import("../pages/auth/login"));

const RouterProvider = (props) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={
            <AuthGuard>
              <AppLayout />
            </AuthGuard>
          }
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/404" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterProvider;
